<template>
    <div class="layout-media" :class="layoutClasses">
        <div class="hidden lg:block" v-if="!flip">
            <slot name="media"></slot>
        </div>
        <div class="flex flex-col justify-center" v-if="$slots.text">
            <div>
                <slot name="text"></slot>
            </div>
        </div>
        <div class="hidden lg:block" v-if="flip">
            <slot name="media"></slot>
        </div>
        <div class="lg:hidden">
            <slot name="media"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LayoutMedia',
    props: {
        flip: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
    computed: {
        layoutClasses () {
            const cls = {};
            cls['grid grid-cols-1 gap-x-16 gap-y-10'] = true;
            cls['lg:grid-cols-2'] = !!this.$slots.text;
            return cls;
        },
    },
};
</script>
